import React, { useContext, useEffect } from "react";
import { ROUTES } from "../Routes";
import { NavLink, useLocation } from "react-router-dom";
import AppContext from "../context/context";
import { IoMdClose } from "react-icons/io";

function Sidenav() {
  const { sideBar, setSideBar } = useContext(AppContext);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const parent = pathname?.split("/")?.at(1);

  useEffect(() => {
    if (sideBar) {
      setSideBar(false);
    }
  }, [pathname]);

  return (
    <div
      className={` flex flex-col gap-2 w-full min-h-full overflow-auto bg-white lg:rounded-md shadow `}
    >
      <div className="flex items-center justify-between gap-2 p-2 bg-slate-600 text-white">
        <b>BabaLudo Admin</b>
        <IoMdClose
          size={30}
          onClick={() => setSideBar(false)}
          className="lg:hidden"
        />
      </div>
      <div className="flex flex-col  gap-4 mt-4 p-3">
        {ROUTES.map((r, i) => {
          if (r?.show) {
            return (
              <NavLink
                to={r?.path}
                className={`p-4 ${
                  page === r?.key || parent === r?.key
                    ? "bg-PRIMARY text-white"
                    : "bg-slate-50 text-black"
                } rounded-lg font-semibold text-sm border`}
              >
                {r?.name}
              </NavLink>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Sidenav;
