import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "../../helper/api";
import { Button, Card, Typography, message } from "antd";
import moment from "moment";
import { WITHDRAWAL_STATUS } from "../../helper/constants";

function WithdrawalView() {
  const { wid } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  
  const [withdraw, setWithdraw] = useState();

  const getData = async () => {
    setLoading(true);
    let res;

    res = await get(`/withdraw?id=${wid}`);
    if (res?.success) {
      setWithdraw(res?.withdraws?.at(0));
    }
    setLoading(false);
  };


  const updateStatus =async(id,status)=>{
    message.open({
        type:"loading",
        key:id,
        content:`Updating status`
    })
    setLoadingStatus(true)
    const res = await put(`/withdraw/update-status/${id}`,{status})

    if(res?.success){
        message.open({
            type:"success",
            key:id,
            content:res?.message
        })
    setLoadingStatus(false)

        getData()
    }else{
        message.open({
            type:"error",
            key:id,
            content:res?.message
        })
    }
}
  useEffect(() => {
    if (wid) {
      getData();
    }
  }, [wid]);

  return (
    withdraw && (
      <Card title={"Withdrawal"}>
        {Object.keys(withdraw)?.map((e, i) => {
         if(e!=="user"&&e!=="__v"&&e!=="updatedAt"){
             return (
            <div className="flex items-center justify-between p-1 my-1">
              <Typography.Text className="capitalize">{e==="createdAt"?"Date":e}</Typography.Text>
              <Typography.Text>{e==="createdAt"?moment(withdraw[e]).format("DD-MM-YYYY, hh:mm a"):withdraw[e]}</Typography.Text>
            </div>
          );}
        })}

        {withdraw?.status===WITHDRAWAL_STATUS.pending&&

        <div className="flex flex-col gap-4 mt-4">
            <Typography.Text>Actions</Typography.Text>
            <div className="flex gap-2">
            <Button onClick={()=>updateStatus(withdraw?._id,WITHDRAWAL_STATUS.approved)} className="bg-green-500 text-white" loading={loadingStatus}>Approve</Button>
            <Button onClick={()=>updateStatus(withdraw?._id,WITHDRAWAL_STATUS.declined)} danger loading={loadingStatus}>Decline</Button>
            </div>
        </div>
        
        }
      </Card>
    )
  );
}

export default WithdrawalView;
